import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '../../components/icon';
import { getCountryCode, otpFindId, resendOtpFindId, validateOtpFindId } from '../../ssoSlicer/ssoSlice';
import { initialStateProps, payloadValidateOtpFIndId } from '../../helpers/types';
import { alertMessage } from '../../helpers/helper';
import Loading from '../../components/loading';
import { LocalizedString } from '../../shared/localization';

const FindId = ({ source }: { source?: string }) => {
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('clientId') || searchParams.get('clientid') || '';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contryCode, resOtpFindId, loading } = useSelector((state: initialStateProps) => state);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  // const [option, setOptions] =
  const [showOtpInput, setShowOtpInput] = useState<boolean>(false);
  const [btnIsDisable, setBtnIsDisable] = useState<boolean>(true);
  const [codePhone, setCodePhone] = useState<string>('+62');
  const [phone, setPhone] = useState<string>('');
  const [otp, setOtp] = useState<string>('');

  const btnSubmitDisable = otp?.length === 6 && showOtpInput;
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  useEffect(() => {
    if (showOtpInput && timeLeft >= 0) {
      const interval = setInterval(() => {
        console.log('timeLeft', timeLeft);
        if (timeLeft >= 0) {
          setTimeLeft((timeLeft) => timeLeft - 1);
        }
        if (timeLeft === 0) {
          setBtnIsDisable(false);
        } else {
          setBtnIsDisable(true);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [showOtpInput, timeLeft]);

  useEffect(() => {
    if (!contryCode.length) {
      dispatch(getCountryCode());
    }
  }, [dispatch, contryCode]);

  const inputChangeHandler = (e: any) => {
    const { name, value } = e.target;
    let re = /^[1-9][0-9]*$/;

    if (value === '' || re.test(value)) {
      if (name === 'phone') {
        setPhone(value);
        value.length >= 6 && setBtnIsDisable(false);
      }
      if (name === 'otp') {
        setOtp(value);
      }
    }
  };

  const otpInputChangeHandler = (e: any) => {
    let re = /^[0-9\b]+$/;
    const { value } = e.target;
    if (value === '' || re.test(value)) {
      setOtp(value);
    }
  };

  const sendVerifHandler = (e: any) => {
    e.preventDefault();
    // if (resOtpFindId.key?.length >= 1) {
    //   dispatch(resendOtpFindId(resOtpFindId.key));
    //   return;
    // }
    const newPhone = phone.replace(/^0+/, '');
    const formatPhone = codePhone + newPhone;
    dispatch(otpFindId(formatPhone))
      .unwrap()
      .then((res: any) => {
        if (res?.status && (res?.status === 500 || res?.status === 400)) {
          const message = res?.message;
          alertMessage.error(message);
          return;
        }

        setShowOtpInput(true);
        setTimeLeft(60);
        setBtnIsDisable(true);
      });
  };

  const onValidate = (e: any) => {
    e.preventDefault();
    const newPhone = phone.replace(/^0+/, '');
    const formatPhone = codePhone + newPhone;
    const payload: payloadValidateOtpFIndId = {
      IdOtpSSO: resOtpFindId.id,
      Key: resOtpFindId.key,
      OTP: otp,
      PhoneNumber: formatPhone,
      RequestUsing: 'phone',
    };
    dispatch(validateOtpFindId(payload))
      .unwrap()
      .then((res: any) => {
        if (res?.response?.status === 500 || res?.response?.status === 400) {
          const message = res?.response?.data?.Message.split('|')[0];
          alertMessage.error(message);
          return;
        }
        navigate(`/success?clientId=${clientId}`);
      });
  };

  const onClose = () => {
    switch (source) {
      case 'ctb':
        window.history.back();
        break;

      default:
        navigate(`/hyundai-one-id?clientId=${clientId}`);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <header className="p-4 flex justify-between items-center">
        <div className="flex items-center justify-between w-full">
          <button style={{ paddingRight: 16 }} onClick={onClose}>
            <Icon icon="close" size={20} />
          </button>
          <strong className="font-1">{LocalizedString.idMigration.btnFindID}</strong>
          <span style={{ width: 36, height: 1 }}>&nbsp;</span>
        </div>
      </header>
      <div className="flex flex-col items-start flex-1 py-6 px-4 bg-background ">
        <div className="flex flex-col items-start">
          <p className="text-lg font-medium hyundaiTextHead">{LocalizedString.idMigration.labelForgotYourId}</p>
          <p className="pt-2 text-sm hyundaiText text-black">{LocalizedString.idMigration.descForgotYourId}</p>
        </div>
        <div className="flex flex-col items-start py-5 w-full">
          <p className="text-base text-black">{LocalizedString.idMigration.labelPhoneNumberMandatory}</p>
          <div className="flex flex-row items-center self-stretch pt-1">
            <Select
              defaultValue={{ label: '+62', value: '+62' }}
              options={contryCode}
              className="mr-2 focus:ring-blue-500 focus:border-primary"
              onChange={(e: any) => setCodePhone(e.value)}
              isDisabled={showOtpInput && minutes >= 0 && seconds >= 0 ? true : false}
            />
            <input
              name="phone"
              maxLength={12}
              value={phone || ''}
              disabled={showOtpInput && minutes >= 0 && seconds >= 0 ? true : false}
              onChange={inputChangeHandler}
              type="text"
              className="bg-gray-50 border border-gray-300 self-stretch  text-base focus:border-primary text-black flex-1"
            />
          </div>
        </div>
        <button
          onClick={sendVerifHandler}
          disabled={btnIsDisable}
          className={`flex flex-row items-center justify-center py-[14px] px-[24px] ${
            btnIsDisable ? 'bg-[#ccc]' : 'bg-primary'
          }  text-base text-white hyundaiTextHead self-stretch`}
        >
          {showOtpInput && minutes >= 0 && seconds >= 0
            ? `Resend Code In ${minutes}: ${seconds}`
            : LocalizedString.idMigration.labelBtnSendVerificationCode}
        </button>
        {showOtpInput && (
          <>
            <div className="flex flex-col items-start py-5 w-full">
              <input
                maxLength={6}
                onChange={otpInputChangeHandler}
                name="otp"
                value={otp}
                placeholder={LocalizedString.idMigration.placeholderVerificationCode}
                type="text"
                className="bg-gray-50 border border-gray-300 self-stretch  text-base focus:border-primary text-black flex-1"
              />
            </div>
            <div className="flex flex-col items-start">
              <div className="flex flex-row items-start">
                <p>{'\u2022'}</p>
                <p className="pl-1 text-sm hyundaiText text-black">
                  {LocalizedString.idMigration.labelVerificationCodeMust}
                </p>
              </div>
              <div className="flex flex-row items-start">
                <p>{'\u2022'}</p>
                <p className="pl-1 text-sm hyundaiText text-black">
                  {LocalizedString.idMigration.labelNotYetReceiveVerificationCode}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="p-4 bg-white flex flex-col self-stretch">
        <button
          onClick={onValidate}
          disabled={!btnSubmitDisable}
          className={`flex flex-row items-center justify-center py-[14px] px-[24px] ${
            !btnSubmitDisable ? 'bg-[#ccc]' : 'bg-primary'
          }  text-base text-white hyundaiTextHead self-stretch`}
        >
          {LocalizedString.idMigration.labelBtnSubmit}
        </button>
      </div>
      {loading && <Loading />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default FindId;
